/* eslint-disable no-useless-escape */
import { format, parseISO, differenceInYears, addDays } from 'date-fns';
import condenseWhitespace from 'condense-whitespace';
import Parser from 'simple-text-parser';

import compact from 'lodash/compact';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import getfp from 'lodash/fp/get';
import isDate from 'lodash/isDate';
import join from 'lodash/join';
import last from 'lodash/last';
import map from 'lodash/map';
import slice from 'lodash/slice';
import flatMap from 'lodash/flatMap';
import sortBy from 'lodash/sortBy';
import toString from 'lodash/toString';
import sum from 'lodash/sum';

import holidays from '@/lib/holidays';
import {
	getPropertyEqualization,
	getRegisteredPropertyEqualization,
} from '@/lib/equalizationCalculations';

const numbers = [
	'zero',
	'one',
	'two',
	'three',
	'four',
	'five',
	'six',
	'seven',
	'eight',
	'nine',
];

const currency = new Intl.NumberFormat('en-CA', {
	style: 'currency',
	currency: 'CAD',
});

const partnerKeyMap = ['partner1', 'partner2'];
const castId = (id) => Number(id);
const castOtherId = (id) => castId(!castId(id));

const trimStart = (string = '') => string.trimStart();
const trimEnd = (string = '') => string.trimEnd();

// returns partner1 for 0, partner2 for 1
const getPartnerKey = (id) => partnerKeyMap[castId(id)];
// return partner2 for 0 and partner1 for 1
const getOtherPartnerKey = (id) => partnerKeyMap[castOtherId(id)];

const getNameElement = (key) => (input, blank) =>
	get(input, `name.${key}`, blank ? '' : undefined);

const getFirst = getNameElement('first');
const getLast = getNameElement('last');
const getMiddle = getNameElement('middle');
const getPreferred = getNameElement('preferred');
const getPronouns = getNameElement('pronouns');

const parseDate = (val) => {
	try {
		return parseISO(val);
	} catch (e) {
		return undefined;
	}
};

const getattrs = (list, path) => {
	return map(list, getfp(path));
};

const filterattr = (list, path, value) => {
	return filter(list, fromPairs([[path, value]]));
};

const rejectattr = (list, path, value) => reject(list, [path, value]);

const makeListLowercase = (list) => {
	return list.map((word) => word.toLowerCase());
};

const mkConjunctionList = (conjunction) => {
	return (list = []) => {
		if (list.length > 1) {
			return `${join(
				slice(list, 0, list.length - 1),
				', '
			)} ${conjunction} ${last(list)}`;
		} else if (list.length === 1) {
			return list[0];
		}

		return '';
	};
};

const formatDateMDY = (val) => {
	const date = isDate(val) ? val : parseDate(val);

	try {
		return format(date, 'MMMM d, yyyy');
	} catch (e) {
		return 'Invalid Date';
	}
};

const formatDateWithSuffix = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    let suffix;
    if (day >= 11 && day <= 13) {
        suffix = "th";
    } else {
        switch (day % 10) {
            case 1: suffix = "st"; break;
            case 2: suffix = "nd"; break;
            case 3: suffix = "rd"; break;
            default: suffix = "th"; break;
        }
    }
    return day + suffix;
};

const formatCurrency = (amount) => {
	return currency.format(Number(amount));
};

const missing = (val) => {
	return toString(val).toLowerCase() === '$nan' ||
		toString(val).toLowerCase() === 'nan' ||
		toString(val).toLowerCase() === 'invalid date' ||
		toString(val) === ''
		? '<span class="missing"><i>To Be Determined</i></span>'
		: val;
};

const preferredName = (input) => getPreferred(input) || getFirst(input, true);

const preferredPronoun = (gender) => getPronouns(gender);

const getExclusions = (propertyMap) => {
	return flatMap(propertyMap, (properties, propertyType) => {
		return flatMap(properties, (property) => {
			return map(property.exclusions, (exclusion) => ({
				...exclusion,
				propertyType,
				property,
			}));
		});
	});
};

const anAPrefix = (word) => {
	if (!word) return '';
	const vowels = ['a', 'e', 'i', 'o', 'u'];
	return vowels.some((vowel) => word.toLowerCase().startsWith(vowel))
		? 'an'
		: 'a';
};

const listAccountDescriptions = (liabilities) => {
	return andList(
		liabilities.map((liability) => {
			const type =
				liability.registered === 'other'
					? liability.type
					: liability.registered;
			return `${liability.lender} ${type} ${getAccountNumber(
				liability
			)}`.trim();
		})
	);
};

const childOrChildren = (children = []) => {
	if (children.length == 1) {
		return 'child';
	}

	return 'Children';
};

const childrenNames = (children = []) => {
	return sortBy(children, 'birthday').map(
		(child) => ` ${preferredName(child)}`
	);
};

const childrenUnder19 = (children = []) => {
	return children.filter(
		({ birthdate }) =>
			differenceInYears(new Date(), parseDate(birthdate)) < 19
	);
};

const youngestChild = (children = []) => {
	const sortedChildren =  children.sort((a, b) => new Date(a.birthdate) - new Date(b.birthdate))
	return preferredName(sortedChildren[sortedChildren.length - 1])
}

// Filters the children above 19 years and not self supporting
const childrenOver19 = (children = []) => {
	return children.filter(
		({ birthdate, selfSupporting }) =>
			differenceInYears(new Date(), parseDate(birthdate)) >= 19 &&
			!selfSupporting
	);
};

// Filters the children above 19 years
const childrenUnder18 = (children = []) => {
	return children.filter(
		({ birthdate }) =>
			differenceInYears(new Date(), parseDate(birthdate)) < 18
	);
};

const childrenSelfSupporting = (children = []) => {
	return children.filter(({ selfSupporting }) => selfSupporting);
};

const childrenNotSelfSupporting = (children = []) => {
	return children.filter(({ selfSupporting }) => !selfSupporting);
};

const over19ChildSupport = (children = [], type) => {
	return children.filter(
		({ applicableChildSupportAspects }) =>
			applicableChildSupportAspects &&
			applicableChildSupportAspects.includes(type)
	);
};

const childrenOver19NotSelfSupporting  = (children = []) => {
	return children.filter(({ selfSupporting }) => selfSupporting === false);
};

const getPronoun = (person, form) => {
	if (form === 'poss') {
		if (person.pronouns === 'male') {
			return 'his';
		} else if (person.pronouns === 'female') {
			return 'her';
		}
		return 'Their';
	} else if (form === 'reflex') {
		if (person.pronouns === 'male') {
			return 'himself';
		} else if (person.pronouns === 'female') {
			return 'herself';
		}
		return 'Themselves';
	} else if (form === 'object') {
		if (person.pronouns === 'male') {
			return 'him';
		} else if (person.pronouns === 'female') {
			return 'her';
		}
		return 'Them';
	} else if (form === 'subject') {
		if (person.pronouns === 'male') {
			return 'he';
		} else if (person.pronouns === 'female') {
			return 'she';
		} else {
			return 'They';
		}
	}else if (form === 'all') {
		if (person.pronouns === 'male') {
			return 'he/him/his';
		} else if (person.pronouns === 'female') {
			return 'she/her/hers';
		} else if (person.pronouns === 'neutral') {
			return 'They/Them/Their';
		} else {
			return ''
		}
	}
};

const smartSum = (items) => sum(items.map((item) => Number(item)));

const andList = mkConjunctionList('and');

const getAccountNumber = ({ lastFourDigits }) =>
	lastFourDigits ? `[${lastFourDigits}]` : '';

const getNoChangeHolidays = (parenting, agreementData) => {
	const holidayMap = holidays(agreementData);

	return {'combinedHolidaySpecialDaysItems': filterByParentingDecision(parenting,filterHolidaySpecialDayMap(holidayMap),'no-change')
												.map(({ label }) => label) ,
			'schoolBreakItems':filterByParentingDecision(parenting,filterSchoolBreakMap(holidayMap),'no-change')
												.map(({ label,value,type }) => (type === 'schoolBreak' && value === 'other') ? parenting.otherSchoolBreakValue : label)
			}
	// return filterByParentingDecision(parenting,holidayMap,'no-change')
	// 		.map(({ label }) => label);
};

const getSuspendedHolidays = (parenting, agreementData) => {
	const holidayMap = holidays(agreementData);
	
	return {
			'combinedHolidaySpecialDaysItems': filterByParentingDecision(parenting,filterHolidaySpecialDayMap(holidayMap),'suspend')
												.map(({ label }) => label) ,
			'schoolBreakItems':filterByParentingDecision(parenting,filterSchoolBreakMap(holidayMap),'suspend')
												.map(({ label,value,type }) => (type === 'schoolBreak' && value === 'other') ? parenting.otherSchoolBreakValue : label)
		   }
	// return filterByParentingDecision(parenting,holidayMap,'suspend')
	// 		.map(({ label }) => label);
};

// function getSpecifiedChangeHolidays(parenting, agreementData) {
// 	const holidayMap = holidays(agreementData);
// 	return filterByParentingDecision(parenting,holidayMap,'specify-change')
// 			.map(({ label, text }) => parenting[text] ? `${label}, ${parenting[text]}` : `${label}`);
// }

function getSpecifiedChangeHolidaysText(parenting, agreementData) {
	//const holidayMap = holidays(agreementData);
	// return filterByParentingDecision(parenting,holidayMap,'specify-change')
	// 		.map((singleHoliday) => formattedHolidayTextForSpecifiedChange(parenting, singleHoliday,holidayMap));
		
	// return {
	// 		'combinedHolidaySpecialDaysItems': filterByParentingDecision(parenting,filterHolidaySpecialDayMap(holidayMap),'specify-change')
	//  											.map((singleHoliday) => formattedHolidayTextForSpecifiedChange(parenting, singleHoliday,filterHolidaySpecialDayMap(holidayMap))),
	// 		'schoolBreakItems':filterByParentingDecision(parenting,filterSchoolBreakMap(holidayMap),'specify-change')
	// 									 		.map((singleHoliday) => formattedHolidayTextForSpecifiedChange(parenting, singleHoliday,filterSchoolBreakMap(holidayMap)))
	// 		}
	return groupByPartnerNamesForSpecifiedChanges(parenting, agreementData,'adjust-parenting-time')
}

const  getSpecifiedChangeAlternateYearsGroups = (parenting, agreementData)=> {
	return groupByPartnerNamesForSpecifiedChanges(parenting, agreementData,'alternate-years','partyWithParentingTimeOnOddYears')
}

const getSpecifiedChangeEveryYearIsSameGroups =(parenting, agreementData)=>{
	return groupByPartnerNamesForSpecifiedChanges(parenting, agreementData,'every-year-is-same','partyWithParentingTimeEveryYear')
}

const getSpecifiedChangeSplitDayGroups =(parenting, agreementData)=>{
	return groupByPartnerNamesForSpecifiedChanges(parenting, agreementData,'split-day-equally')
}

const getSpecifiedChangeSplitTimeGroups =(parenting, agreementData)=>{
	return groupByPartnerNamesForSpecifiedChanges(parenting, agreementData,'split-time-equally')
}
const getSpecifiedChangeOtherGroups =(parenting, agreementData)=>{
	return groupByPartnerNamesForSpecifiedChanges(parenting, agreementData,'other')
}

const groupByPartnerNamesForSpecifiedChanges =(parenting, agreementData,holidayDecisionSpecifyChange,partnerIdNaming)=>{
	const holidayMap = holidays(agreementData);
	const filteredItems = filterByParentingDecision(parenting,holidayMap,'specify-change').map(hol => hol.value);
	const combinedHolidaySpecialDays = holidaySpecialDaysListCombined(parenting).filter(hol=> filteredItems.includes(hol.value));
	const schoolBreakDays = [...parenting.schoolBreakList].filter(hol=> filteredItems.includes(hol.value));

	const combinedHolidaySpecialDaysByPartner = { 
		'partner1': [...combinedHolidaySpecialDays].filter(day => day.holidayDecisionSpecifyChange == holidayDecisionSpecifyChange && (partnerIdNaming ? (day[partnerIdNaming]=='0') : true))
												   .map((singleHoliday) => formattedHolidayTextForSpecifiedChange(parenting, singleHoliday,filterHolidaySpecialDayMap(holidayMap),holidayDecisionSpecifyChange)),
		'partner2': [...combinedHolidaySpecialDays].filter(day => day.holidayDecisionSpecifyChange == holidayDecisionSpecifyChange && (partnerIdNaming ? ( day[partnerIdNaming]=='1'): true))
												   .map((singleHoliday) => formattedHolidayTextForSpecifiedChange(parenting, singleHoliday,filterHolidaySpecialDayMap(holidayMap),holidayDecisionSpecifyChange))
	}

	const schoolBreakGroupsByPartner = { 
		'partner1': [...schoolBreakDays].filter(day => day.holidayDecisionSpecifyChange == holidayDecisionSpecifyChange && (partnerIdNaming ? (day[partnerIdNaming]=='0') : true))
												   .map((singleHoliday) => formattedHolidayTextForSpecifiedChange(parenting, singleHoliday,filterSchoolBreakMap(holidayMap),holidayDecisionSpecifyChange)),
		'partner2': [...schoolBreakDays].filter(day => day.holidayDecisionSpecifyChange == holidayDecisionSpecifyChange && (partnerIdNaming ? ( day[partnerIdNaming]=='1'): true))
												   .map((singleHoliday) => formattedHolidayTextForSpecifiedChange(parenting, singleHoliday,filterSchoolBreakMap(holidayMap),holidayDecisionSpecifyChange))
	}
	return {'combinedHolidaySpecialDaysItems': combinedHolidaySpecialDaysByPartner , 'schoolBreakItems':schoolBreakGroupsByPartner}
}

function formattedHolidayTextForSpecifiedChange(parenting, singleHoliday,holidayMap,holidayDecisionSpecifyChange) {
	let formattedText = `${singleHoliday.label}`;
	let indexFromHolidayMap = holidayMap.findIndex(holiday => holiday.value == singleHoliday.value);
	if( holidayDecisionSpecifyChange == 'other' && singleHoliday.decisionSpecifyChangeOtherValue)
		formattedText +=`, ${singleHoliday.decisionSpecifyChangeOtherValue}`

	// if(singleHoliday.type== 'holiday'){
	// 	formattedText = appendTextToHolidayListItem(formattedText,parenting.holidayList[indexFromHolidayMap])
	// } else if (singleHoliday.type== 'specialDays'){
	// 	formattedText = appendTextToHolidayListItem(formattedText,parenting.specialDaysList[indexFromHolidayMap])
	if(singleHoliday.type== 'holiday' || singleHoliday.type== 'specialDays'){
		try{
		formattedText = appendTextToHolidayListItem(formattedText,parenting.holidayAndSpecialDaysList[indexFromHolidayMap])	
		}catch(err){
			console.log('err->',err)
		}
	} else if (singleHoliday.type== 'schoolBreak'){
		formattedText = appendTextToHolidayListItem(formattedText,parenting.schoolBreakList[indexFromHolidayMap])
	}
	return formattedText;
}


const  getClauseForSummerBreaks = (parenting)=> {
	let resultText='.';
	if(parenting.summerBreak && parenting.summerBreakDecision =='specify-change'){
		let summerBreakItem = findHolidayFromSchoolBreakUsingValue(parenting,'summerBreak');
		let indexOfSummerBreak = parenting.schoolBreakList.findIndex(hol=> hol.value == 'summerBreak')
		if(indexOfSummerBreak > -1){
			let specifyChangeOption = parenting.schoolBreakList[indexOfSummerBreak].holidayDecisionSpecifyChange;
			if(summerBreakItem && summerBreakItem.holidayTransitionTimesAgreedBetweenParties && specifyChangeOption && specifyChangeOption == 'alternate-weeks'){
				if(summerBreakItem.holidayTransitionTimesAgreedBetweenParties =='transition-times-agreed')
					resultText = ', with transition times as agreed between the parties.'
				else if(summerBreakItem.holidayTransitionTimesAgreedBetweenParties =='other')
					resultText = summerBreakItem.holidayTransitionTimesAgreedBetweenPartiesOtherValue ? `, ${summerBreakItem.holidayTransitionTimesAgreedBetweenPartiesOtherValue}.`:'.'
			}
		}
	}
	return resultText;
}

const appendTextToHolidayListItem = (formattedText,itemFromHolidayList)=>{
	if(itemFromHolidayList && (itemFromHolidayList.holidayTransitionTimesAgreedBetweenParties)){
		const holidayTransitionTime = itemFromHolidayList.holidayTransitionTimesAgreedBetweenParties;
		if(holidayTransitionTime == 'transition-times-agreed'){
			formattedText += ', with transition times as agreed between the parties'
		} else if(holidayTransitionTime == 'other'){
			formattedText += itemFromHolidayList.holidayTransitionTimesAgreedBetweenPartiesOtherValue ? `, ${itemFromHolidayList.holidayTransitionTimesAgreedBetweenPartiesOtherValue}` : ''
		} else if(holidayTransitionTime == 'christmasEve'){
			formattedText += ', from 12:00 pm on December 24th to 12:00 p.m. on December 25th'
		} else if(holidayTransitionTime == 'christmasDay'){
			formattedText += ', from 12:00 pm on December 25th to 12:00 p.m. on December 26th'
		}
	}
	return formattedText;
}

const filterByParentingDecision = (parenting, holidayMap, selectedDecision)=>{
	return holidayMap.filter(({ value, decision }) => parenting[value] && (parenting[decision] === selectedDecision))
}

const filterHolidaySpecialDayMap=(holidayMap)=>{
	return holidayMap.filter(({ type })=> (type == 'holiday' || type == 'specialDays'));
}

const filterSchoolBreakMap=(holidayMap)=>{
	return holidayMap.filter(({ type })=> (type == 'schoolBreak'));
}

const findHolidayFromSchoolBreakUsingValue=(parenting,value)=>{
	return parenting.schoolBreakList ? parenting.schoolBreakList.find(schoolBreak=> schoolBreak.value == value) : {};
}

const holidaySpecialDaysListCombined = (parenting)=>{
	// let holList = [...parenting.holidayList];
	// let specialList = [...parenting.specialDaysList];
	// while(specialList.length)
	//  holList.push(specialList.shift())
	// return holList;
	return [...parenting.holidayAndSpecialDaysList]
}

const propertyEqualization = (agreementData) => {
	const equalization = getPropertyEqualization(agreementData);
	let amount = 0,
		from = '',
		to = '';
	const partner1Name = getPartnerName('0', agreementData);
	const partner2Name = getPartnerName('1', agreementData);
	if (equalization.spouseOneOverall > equalization.spouseTwoOverall) {
		amount = equalization.spouseOneOverall;
		from = partner1Name;
		to = partner2Name;
	} else {
		amount = equalization.spouseTwoOverall;
		from = partner2Name;
		to = partner1Name;
	}

	return {
		amount,
		from,
		to,
		...equalization,
	};
};

const registeredPropertyEqualization = (agreementData) => {
	const equalization = getRegisteredPropertyEqualization(agreementData);

	let amount = 0,
		from = '',
		to = '';
	const partner1Name = getPartnerName('0', agreementData);
	const partner2Name = getPartnerName('1', agreementData);
	if (equalization.spouseOne > equalization.spouseTwo) {
		amount = equalization.spouseOne;
		from = partner1Name;
		to = partner2Name;
	} else {
		amount = equalization.spouseTwo;
		from = partner2Name;
		to = partner1Name;
	}

	return {
		amount,
		from,
		to,
		...equalization,
	};
};

const isAre = (list = []) => {
	return list.length === 1 ? 'is' : 'are';
};

const mergeArray = (array, anotherArray) => {
	return [...array, ...anotherArray];
};

const parentChildSpouseOrCondition = (agreementSections) => {
	let parentChildSpousePresentLength = 0;
	if(agreementSections.includes("Parenting")){
		parentChildSpousePresentLength = 1
	}
	if(agreementSections.includes("Child Support")){
		parentChildSpousePresentLength += 1
	}
	if(agreementSections.includes("Spousal Support")){
		parentChildSpousePresentLength += 1
	}
	let resultMsg = (parentChildSpousePresentLength>=2) ? 'and any of ' : 'and ';
	return resultMsg;
};

const filteredRRSPwithDiscountItems = (RRSPs)=>{
	let filteredItems = RRSPs.filter(rrsp=> rrsp.discountRRSPForTax == true && rrsp.taxRateToApplyRRSP);
	let resultArray = [];
	if(filteredItems.filter(item=> item.ownership=='0').length)
	resultArray.push(filteredItems.filter(item=> item.ownership=='0'))

	if(filteredItems.filter(item=> item.ownership=='1').length)
	resultArray.push(filteredItems.filter(item=> item.ownership=='1'))
	return resultArray;
}	

const discountedValueInRRSP = (RRSPValue,discount)=>{
	let discountedValue = RRSPValue * ((100 - discount)/100);
	return discountedValue
}

const convertToString = (value)=>{
	return JSON.stringify(value)
}

const filterIfFieldsPresent = (items,fieldsList)=>{
	return items.filter((it) => {return fieldsList.every((field)=> it[field])})
}

const pureFilters = {
	anAPrefix,
	andList: mkConjunctionList('and'),
	castId,
	castOtherId,
	childOrChildren,
	childrenNames,
	youngestChild,
	childrenUnder19,
	childrenOver19,
	childrenSelfSupporting,
	childrenNotSelfSupporting,
	childrenOver19NotSelfSupporting,
	over19ChildSupport,
	condenseWhitespace: (str) => condenseWhitespace(str.toString()),
	datePlusDays: (date, add) => addDays(parseDate(date), add),
	dateToAge: (date) => differenceInYears(new Date(), parseDate(date)),
	filterattr,
	rejectattr,
	firstLastName: (input) =>
		condenseWhitespace(`${getFirst(input, true)} ${getLast(input, true)}`),
	formatCurrency: (num) => formatCurrency(num),
	formatPercent: (num) => `${Number(num)}%`,
	formatDateMDY,
	formatDateWithSuffix,
	fullName: (input) =>
		condenseWhitespace(
			`${getFirst(input, true)} ${getMiddle(input, true)} ${getLast(
				input,
				true
			)}`
		),
	getattrs,
	getPartnerObjectById: (id, agreementData) =>
		agreementData[getPartnerKey(id)],
	legalNumber: (input) => (0 <= input && input <= 9 ? numbers[input] : input),
	isAre,
	missing,
	mergeArray,
	orList: mkConjunctionList('or'),
	preferredName,
	preferredNames: (arr) => map(arr, preferredName),
	preferredPronoun,
	uniqueShareholders: (input) => compact(map(input.shares, 'shareholder')),
	getPronoun,
	makeListLowercase,
	listAccountDescriptions,
	smartSum,
	getAccountNumber,
	getSuspendedHolidays,
	propertyEqualization,
	registeredPropertyEqualization,
	// getSpecifiedChangeHolidays,
	trimEnd,
	trimStart,
	parentChildSpouseOrCondition,
	filteredRRSPwithDiscountItems,
	discountedValueInRRSP,
	getNoChangeHolidays,
	getSpecifiedChangeHolidaysText,
	getSpecifiedChangeAlternateYearsGroups,
	getSpecifiedChangeEveryYearIsSameGroups,
	getSpecifiedChangeSplitDayGroups,
	getSpecifiedChangeSplitTimeGroups,
	getSpecifiedChangeOtherGroups,
	getClauseForSummerBreaks,
	filterIfFieldsPresent,
	convertToString,
	childrenUnder18
};

const parser = new Parser();

// creates underscore rule for making italic runs
parser.addRule(/\_(.*?)\_/g, (run, clean_run) => {
	return {
		type: 'text',
		italic: true,
		text: clean_run,
	};
});

const getPartnerName = (val, agreementData, other = false) => {
	if (val === '0' || val === '1') {
		const partnerKey = other ? getOtherPartnerKey(val) : getPartnerKey(val);
		return pureFilters.preferredName(agreementData[partnerKey]);
	}

	return val;
};

const getOtherPartnerName = (val, agreementData) => {
	if (val === '0' || val === '1') {
		const partnerKey = getOtherPartnerKey(val);
		return pureFilters.preferredName(agreementData[partnerKey]);
	}

	return val;
};

const nunjuckFilters = {
	contentToRuns: function (str) {
		return parser.toTree(str);
	},
	getPartnerById: function (id) {
		return pureFilters.getPartnerObjectById(id, this.ctx);
	},
	getPartnerPreferredNameOrPassthru: function (idOrString) {
		return getPartnerName(idOrString, this.ctx);
	},
	getPartnerName: function (idOrString) {
		return getPartnerName(idOrString, this.ctx);
	},
	getOtherPartnerName: function (idOrString) {
		return getPartnerName(idOrString, this.ctx, true);
	},
	getOtherPartnerById: function (id) {
		return this.ctx[getOtherPartnerKey(id)];
	},
	getNoChangeHolidays: function (parenting) {
		return getNoChangeHolidays(parenting, this.ctx);
	},
	getSuspendedHolidays: function (parenting) {
		return getSuspendedHolidays(parenting, this.ctx);
	},
	// getSpecifiedChangeHolidays: function (parenting) {
	// 	return getSpecifiedChangeHolidays(parenting, this.ctx);
	// },
	getSpecifiedChangeHolidaysText: function (parenting) {
		return getSpecifiedChangeHolidaysText(parenting, this.ctx);
	},
	getSpecifiedChangeAlternateYearsGroups: function (parenting) {
		return getSpecifiedChangeAlternateYearsGroups(parenting, this.ctx);
	},
	getSpecifiedChangeEveryYearIsSameGroups: function (parenting) {
		return getSpecifiedChangeEveryYearIsSameGroups(parenting, this.ctx);
	},
	getSpecifiedChangeSplitDayGroups: function (parenting) {
		return getSpecifiedChangeSplitDayGroups(parenting, this.ctx);
	},
	getSpecifiedChangeSplitTimeGroups: function (parenting) {
		return getSpecifiedChangeSplitTimeGroups(parenting, this.ctx);
	},
	getSpecifiedChangeOtherGroups: function (parenting) {
		return getSpecifiedChangeOtherGroups(parenting, this.ctx);
	},
	propertyEqualization: function () {
		return propertyEqualization(this.ctx);
	},
	registeredPropertyEqualization: function () {
		return registeredPropertyEqualization(this.ctx);
	},
};

export {
	andList,
	childOrChildren,
	childrenOver19,
	childrenUnder19,
	pureFilters,
	nunjuckFilters,
	getAccountNumber,
	getPartnerName,
	getExclusions,
	getOtherPartnerName,
	getPronoun,
	preferredName,
	propertyEqualization,
	registeredPropertyEqualization,
	parentChildSpouseOrCondition,
	filteredRRSPwithDiscountItems,
	discountedValueInRRSP,
	convertToString,
	childrenUnder18
};
export default { ...pureFilters, ...nunjuckFilters };
