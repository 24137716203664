<script>
import { BIconPower,BIconPersonCircle , BIconCreditCard} from 'bootstrap-vue';
import { mapState } from 'vuex';
import {currentRoleLevelName} from '@/mixins/roleLevelPermissions';

import clientUserFormHelper from '@/mixins/clientUserFormHelper';
export default {
	props: ['user'],
	components: {
		BIconPower,
		BIconPersonCircle,
		BIconCreditCard
	},
	computed:{
	appVersion: function () {
		return '1.10.0'
    },
	imgAvailabilty(){
		return this.imgUrlExists(this.organizationImg)
	},
	...mapState(['lawyerUid','userProfile','storeIsSharedUser','currentAgreementRoleLevelName']),
	organizationImg() {
		if (this.lawyerUid) {
			return `https://firebasestorage.googleapis.com/v0/b/${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com/o/userDocs%2F${this.lawyerUid}-logo.png?alt=media`;
		} else {
			return null;
		}
	},
	clientUserAgreement(){
		return this.currentAgreementRoleLevelName == 'client-sharing-user' ? true : false;
	},
	hasActiveSubscription() {
			// let isActive = (
				return this.userProfile.subscriptionStatus
				//  === true || this.userProfile.subscriptionStatus === 'active') ? true : false
			// return isActive;
	},
	setUserName(){
		let emailId = '';
		let uName = '';
		if(localStorage.getItem('loginProvider') == 'CLIO' || localStorage.getItem('isClioFetch') == 'true'){
			let clioUser = localStorage.getItem('clioUser');
			if(clioUser && JSON.parse(clioUser)){
				emailId = JSON.parse(clioUser).email;
			}
		} else {
			if(this.user)
			emailId = this.user.email;
		}
		uName = this.user ? (this.user.displayName == null || this.user.displayName == undefined 
				|| this.user.displayName == '') ? emailId : this.user.displayName : '';

		return uName;
	},
  },
  data(){
	return{
		// clientUserAgreementId: localStorage.getItem("clientUserAgreement"),
		isImgAvailable: true,
		displayName:'',
		showDropDown: true,
		hideNavToggle: false,
		isUserDataLoaded: false
	}
  },
  mixins: [clientUserFormHelper],
  created() {
    // Listen for the agreements-list-loaded event emitted by the Vue instance
    this.$root.$on('agreements-list-loaded', this.retryCustomerProfileSetting);
  },
  mounted(){
	if(this.$store.getters.isAuthenticated){
		this.$store.dispatch('fetchCurrentCustomerProfile')}
	// if(localStorage.getItem('clientUserAgreement')){
	// 	this.clientUserAgreementId = localStorage.getItem('clientUserAgreement')
	// }
  },
  	watch: { 
		'$store.getters.isAuthenticated': function() {
			if(!this.isUserDataLoaded){
				this.$store.dispatch('fetchCurrentCustomerProfile');
				this.isUserDataLoaded = true
			}
        },
		$route (to, from){
			// this.displayName = this.setUserName();
        	// this.clientUserAgreementId = localStorage.getItem('clientUserAgreement');
			// console.log('to==>',to)
			if(to.name ==='clioHome'){
				this.hideNavToggle = true;
			} else {
				this.hideNavToggle = false;
				// this.displayName = this.setUserName();
			}
    	}
      },
	methods: {
		logout() {			
			// this.clientUserActionOnLogout();
			this.$store.dispatch('logout').then(() => {
				if (location.pathname !== '/trial'){
					// if(this.getPreviouslyLoggedClientUserAgreement)
					// this.$router.push({ path: '/login' ,params: { id: this.getPreviouslyLoggedClientUserAgreement }});
					// else
					this.$router.push({ path: '/auth' });
				} else {
					this.$router.push({ path: '/auth' });
				}
			}).catch((err)=>{
				console.log('logout catch',err)
			});
		},
		goHome() {
			console.log('click')
			if(this.$route.name == 'auth'){
				this.$router.replace({
                        query: { ...this.$route.query, page: 1, auth:0 },
                }).catch(() => {})
			} else
			// if (this.$route.name !== 'agreementsHome' && this.$route.name !== 'login' && !localStorage.getItem('clientUserAgreement')) {
			
			if (this.$route.name !== 'agreementsHome' && this.$route.name !== 'login') {
				this.$store.dispatch('resetAgreement').then(() => {
					this.$store.dispatch('fetchCurrentCustomerProfile')
					this.$router.push({ name: 'home' })
				});
			}
		},
		imgUrlExists(url) {
			let http = new XMLHttpRequest();
			http.open('HEAD', url, false);
			http.send();
			if (http.status != 404)
				return true;
			else
				return false;
    	},
		nonClickableLogo(){
			//return (this.$route.name === 'login' || this.$route.name === 'trial' || this.$route.name === 'agreementsHome' || this.clientUserAgreementId || this.$route.name === 'auth')
			return (this.$route.name === 'login' || this.$route.name === 'trial' || this.$route.name === 'agreementsHome')
		},
		// clientUserActionOnLogout(){
		// 	const clientUserAgrId = localStorage.getItem('clientUserAgreement') ? localStorage.getItem('clientUserAgreement') : localStorage.getItem('previouslyLoggedClientUserAgreement')
		// 	if(clientUserAgrId){
		// 		localStorage.setItem('previouslyLoggedClientUserAgreement', clientUserAgrId);
		// 	} else {
		// 		localStorage.removeItem('previouslyLoggedClientUserAgreement');
		// 	}
		// 	localStorage.removeItem('openUserLoginAs');
		// 	localStorage.removeItem('clientUserAgreement');
		// },
		toggleDropdown(){
			this.showDropDown = true
			const dropdown = this.$refs.navUserDropdown
			if(this.showDropDown === undefined){
				this.showDropDown = true
			}
			
			if(this.showDropDown){
				dropdown.show()
				// this.showDropDown = false
			}
			else if(!this.showDropDown){
				dropdown.hide()
				this.showDropDown = true
			}
		},
		retryCustomerProfileSetting(){
			setTimeout(() => {
				if(this.$store.getters.isAuthenticated){
					this.$store.dispatch('fetchCurrentCustomerProfile')
				}
			}, 3000);
		}
	},
};
</script>

<style scoped>
/* nav {
	background-color: white !important;
} */
.logo {
	cursor: pointer;
	transition-duration: 0.5s;
	opacity: 1.0;
}
.logo:hover {
	opacity: 0.5;
}
.b-icon{
	padding-right: 0.25rem;
  margin-right: 0.25rem;
}
.version{
	border-top:1px solid #ccc;
	margin:5px;
	padding:5px;
	font-size: 0.675rem;
	text-align: center;
	color: #3F6772;
}

.non-clickable {
  pointer-events: none;
}

.org-image{
	max-width: 8rem;
	height: 3rem;
}
</style>

<template>
	<b-navbar
		id="navbar"
		type="dark"
		variant="primary"
		toggleable="sm"
		class="border-bottom"
		sticky
	>
		<b-navbar-brand class="logo" @click="goHome" :class="{ 'non-clickable': nonClickableLogo() }">
			<img v-if="clientUserAgreement && $route.name === 'agreementsHome'" src="@/assets/Divii-logo.svg" alt="divii logo" width="100" height="45" />
			<img v-else-if="clientUserAgreement && isImgAvailable && $route.name !== 'home'" @error="isImgAvailable = false" class="org-image" :src="organizationImg" />
			<!-- <template v-else-if="clientUserAgreement && !isImgAvailable"></template> -->
			<img v-else src="@/assets/Divii-logo.svg" alt="divii logo" width="100" height="45" />
		</b-navbar-brand>
		<b-navbar-toggle target="nav-collapse" @click="toggleDropdown" v-if="$store.getters.isAuthenticated && !hideNavToggle"></b-navbar-toggle>

		<b-collapse id="nav-collapse" is-nav v-if="!hideNavToggle">
			<b-navbar-nav  v-if="$store.getters.isAuthenticated && ($route.name !== 'auth' && $route.name !== 'login') && user" class="ml-auto">
				<b-nav-item-dropdown id="nav-user" :text="setUserName" right data-testid="user-dropdown" ref="navUserDropdown">
					<b-dropdown-item
						v-if="!clientUserAgreement"
						id="nav-profile"
						@click="$router.push({ name: 'profile' })"
						data-testid="profile-item"
					>
					<b-icon-person-circle aria-hidden="true" data-testid="account"></b-icon-person-circle>Account
					</b-dropdown-item>
					<b-dropdown-item
						v-if="!clientUserAgreement"
						id="nav-subscription"
						@click="$router.push({ name: 'subscription' })"
						data-testid="nav-subscription"
					>
					<b-icon-credit-card aria-hidden="true"></b-icon-credit-card>Subscription
					</b-dropdown-item>
					<b-dropdown-item id="nav-logout" @click="logout" data-testid="logout-button">
						<b-icon-power aria-hidden="true" data-testid="logout-icon"></b-icon-power>Logout
					</b-dropdown-item>
					<div class="version">Version {{appVersion}}</div>
				</b-nav-item-dropdown>
			</b-navbar-nav>
		</b-collapse>
	</b-navbar>
</template>
